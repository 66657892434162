export enum DataSettingsTab {
  METADATA_VIEWER = 'METADATA_VIEWER',
  GROUPING_LIST_EDITOR = 'GROUPING_LIST_EDITOR',
}

export const getLabel = (tab: DataSettingsTab) =>
  ({
    [DataSettingsTab.METADATA_VIEWER]: 'Metadata Viewer',
    [DataSettingsTab.GROUPING_LIST_EDITOR]: 'Grouping List Editor',
  }[tab]);
