// @ts-strict-ignore
import { FC, memo, useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { paletteP50 as colors } from '../shared/colorPalettes';
import { legendWrapperStyles } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { useChartOpacity } from './helpers/chartingHelpers';

// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel: any = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index,
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

const PieChartViz: FC<ReportProps & { reportConvertedData: ReportConvertedData }> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(
    () =>
      setReportConvertedData(
        props.reportConvertedData || DataConverters.PIE_CHART(props.reportRawData),
      ),
    [props.reportRawData, props.reportConvertedData],
  );

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const oR = (props.height < props.width * 0.8 ? props.height : props.width * 0.8) * 0.3 + 10;

  const cx = props.width / 3 - 20;
  const cy = props.height / 2 - 20;

  const pieChartData = reportConvertedData.rows;

  const pieCharts = reportConvertedData.headers.map((el, index) => {
    return (
      <Pie
        key={el}
        dataKey={el}
        nameKey={reportConvertedData.groupingHeader.name}
        data={pieChartData}
        labelLine={false}
        // label={props.sequenceId && renderCustomizedLabel} //this hides it for dashlet
        outerRadius={oR}
        fill='#8884d8'
        cx={cx + (oR * 2 + 5) * index} // to be used with multiple pies
        // cx={cx} // to be used with just one pie
        cy={cy}
        onClick={event => event && props.onElementClick(event[`${el}rowcol`])(event)}
      >
        {pieChartData.map((entry, index) => (
          <Cell
            key={entry}
            fill={colors[index % colors.length]}
            fillOpacity={opacity[entry.Grouping] || 1}
          />
        ))}
      </Pie>
    );
  });

  return (
    <PieChart width={props.width} height={props.height}>
      <Tooltip formatter={(value, name, props) => props.payload[`${props.dataKey}Formatted`]} />
      <Legend
        layout='vertical'
        align='right'
        verticalAlign='top'
        wrapperStyle={legendWrapperStyles.vertical}
        onMouseEnter={onChartEnter(reportConvertedData.rows.map(entry => entry.Grouping))}
        onMouseLeave={onChartLeave}
      />
      {pieCharts}
    </PieChart>
  );
};

export default memo(PieChartViz);
