// @ts-strict-ignore
import deepEqual from 'deep-equal';
import {
  getCopyableStylePropsForSelection,
  getFirstSharedApplicableStyleRule,
} from '../../model/styles/styleRule';
import {
  StyleTarget,
  StyleTargetType,
  styleTargetTypesForSelection,
} from '../../model/styles/styleTarget';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import {
  createObject,
  decomposeCellId,
  extractWorkspacePayloadAttr,
  isReservedSequenceId,
  resolveDefaultReportDefinition,
} from '../../shared/utils';
import { AppState } from '../configureStore';

export const isDesignerDirty = (state: AppState) => {
  const currentDefinition = state.report.reportDefinition[DESIGNER_SEQUENCE_ID];
  const matchingWorkspaceDefinition = Object.entries(state.report.reportDefinition).find(
    ([key, def]) => !isReservedSequenceId(Number(key)) && def.id === currentDefinition.id,
  );
  const baseReportDefinition = matchingWorkspaceDefinition
    ? matchingWorkspaceDefinition[1]
    : state.reportDesigner.panelControl.originalDetachedReport
    ? state.reportDesigner.panelControl?.originalDetachedReport
    : resolveDefaultReportDefinition(state);

  return !deepEqual(currentDefinition, baseReportDefinition);
};

export const getFilter = (state: AppState) => (charId: number) =>
  state.report.reportDefinition[DESIGNER_SEQUENCE_ID]?.filters.find(f => f.charId === charId);

export const getAllowedStyleTargetTypesForSelection = (sequenceId: number) => (state: AppState) => {
  const reportDefinition = state.report.reportDefinition[sequenceId];
  const detailList = extractWorkspacePayloadAttr<boolean | undefined>(
    'detailList',
    sequenceId,
    state,
  );

  if (reportDefinition.legacyReport || detailList) {
    return createObject({
      items: styleTargetTypesForSelection,
      createValue: () => false,
    });
  }

  const cellIds =
    extractWorkspacePayloadAttr<string[]>('selectedElements', sequenceId, state) ?? [];

  const decomposedIds = cellIds.map(decomposeCellId);

  const rowIds = new Set(decomposedIds.map(({ rowId }) => rowId));
  const columnIds = new Set(decomposedIds.map(({ columnId }) => columnId));

  return {
    [StyleTargetType.CELL]: cellIds.length > 0,
    [StyleTargetType.ROW]: rowIds.size === 1,
    [StyleTargetType.COLUMN]: columnIds.size === 1,
  };
};

export const getApplicableUniformStyleRule = (
  sequenceId: number | null,
  targets: StyleTarget[],
) => (state: AppState) => {
  if (!sequenceId || targets.length === 0) {
    return null;
  }

  switch (targets[0].type) {
    case StyleTargetType.CELL:
    case StyleTargetType.ROW:
    case StyleTargetType.COLUMN: {
      return (
        getFirstSharedApplicableStyleRule(
          state.report.reportDefinition[sequenceId]?.styleData?.uniformStyles ?? [],
          targets,
        )?.styleProps ?? null
      );
    }

    case StyleTargetType.NORMAL_GROUPING: {
      const target = targets[0];
      return (
        state.report.reportDefinition[sequenceId]?.[`${target.direction}Chars`]?.find(
          layer => layer.layerId === target.charId && layer.modifier === target.modifier,
        )?.styleProps ?? null
      );
    }
  }
};

export const getCopyableStyleProps = (sequenceId: number | null, cellIds: string[]) => (
  state: AppState,
) =>
  sequenceId
    ? getCopyableStylePropsForSelection(
        state.report.reportDefinition[sequenceId]?.styleData?.uniformStyles ?? [],
        cellIds,
      )
    : createObject({
        items: Object.values(StyleTargetType),
        createValue: () => null,
      });
