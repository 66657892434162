import { ContentPage } from '../../components/main/PageContent/contentPage';
import { StyleProps } from '../../model/styles/styleProps';
import { StyleTarget, StyleTargetType } from '../../model/styles/styleTarget';
import { ChangeWorkspaceModalStatus } from './changeWorkspaceModalStatus';
import { OverwriteConfirmationStatus } from './overwriteConfirmationStatus';

export interface UiState {
  activePage: ContentPage;
  intendedPage: ContentPage | null;
  previousPage: ContentPage | null;

  overwriteConfirmationModalStatus: OverwriteConfirmationStatus | null;
  changeWorkspaceModalStatus: ChangeWorkspaceModalStatus | null;
  closeDesignerModalOpen: boolean;
  closeDataSettingsModalOpen: boolean;
  closeGroupingListsEditorMode: 'metadata' | 'navrail' | null;

  uniformStylesModalState: {
    sequenceId: number;
    targetType: StyleTargetType;
    targets: StyleTarget[];
  } | null;
  copiedStyleProps: StyleProps | null;

  isAboutOpen: boolean;
  isRemoteConsoleOpen: boolean;
  pendingCsvExports: string[];
  isFoliaSyntaxGuideOpen: boolean;
}

export const DEFAULT_CONTENT_PAGE = ContentPage.HOME;
export const createDefaultUiState = (): UiState => ({
  activePage: DEFAULT_CONTENT_PAGE,
  intendedPage: null,
  previousPage: null,

  overwriteConfirmationModalStatus: null,
  changeWorkspaceModalStatus: null,
  closeDesignerModalOpen: false,
  closeDataSettingsModalOpen: false,
  closeGroupingListsEditorMode: null,

  uniformStylesModalState: null,
  copiedStyleProps: null,

  isAboutOpen: false,
  isRemoteConsoleOpen: false,
  pendingCsvExports: [],
  isFoliaSyntaxGuideOpen: false,
});
