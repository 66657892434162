// @ts-strict-ignore
import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, Sector } from 'recharts';
import { paletteP50 as colors } from '../shared/colorPalettes';
import { legendWrapperStyles } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { useChartOpacity } from './helpers/chartingHelpers';

const Wrapper = styled.div`
  .recharts-legend-wrapper {
    max-width: 180px !important;

    .recharts-legend-item {
      display: grid !important;
      grid-template-columns: auto auto;
      justify-content: flex-start;
      grid-gap: 5px;
      margin-right: 10px !important;
      margin-bottom: 6px !important;

      .recharts-surface {
        width: 18px;
      }
    }
  }
`;

const renderActiveShape = (kind: any, nameKey: string) => (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const formattedValue = props[`${kind}Formatted`];
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload[nameKey]}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-18}
        textAnchor={textAnchor}
        fill='#333'
      >{`${kind}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill='#333'
      >{`${formattedValue}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const ActivePieChartViz: FC<ReportProps> = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.ACTIVE_PIE_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const onPieEnter = (data: {}, index: number) => {
    setActiveIndex(index);
  };

  const series = reportConvertedData.headers;

  // TODO somework on how to use with multiple pies
  // const oR = (this.props.height < this.props.width / series.length / 2 * .8 ? this.props.height : this.props.width / series.length / 2 * .8) * .3;
  // const thickness = oR*.3;
  // const cx = this.props.width / series.length / 2;
  // const cy = this.props.height / 2;

  const oR = (props.height < props.width * 0.8 ? props.height : props.width * 0.8) * 0.3 + 10;
  const thickness = oR * 0.3;
  const cx = props.width / 3 - 10;
  const cy = props.height / 2 - 20;

  const activePieChartData = reportConvertedData.rows;
  let kind = props.reportRawData.extras.kind;
  if (!kind) {
    kind = reportConvertedData.groupingHeader.name;
  }

  const pieCharts = series.map((el, index) => {
    return (
      <Pie
        key={el}
        dataKey={el}
        nameKey={kind}
        activeIndex={activeIndex}
        activeShape={renderActiveShape(el, kind)}
        data={activePieChartData}
        // TODO a more advanced algorithm can be created to place multiple pies in a matrix
        cx={cx + cx * index} // to be used with multiple pies
        // cx={cx} // to be used with just one pie
        cy={cy}
        innerRadius={oR - thickness}
        outerRadius={oR}
        fill='#8884d8'
        onMouseEnter={onPieEnter}
        onClick={event => event && props.onElementClick(event[`${el}rowcol`])(event)}
      >
        {activePieChartData.map((entry: any, index: any) => (
          <Cell
            key={entry}
            fill={colors[index % colors.length]}
            fillOpacity={opacity[entry.Grouping] || 1}
          />
        ))}
      </Pie>
    );
  });

  return (
    <Wrapper>
      <PieChart width={props.width} height={props.height}>
        {pieCharts}
        <Legend
          layout='vertical'
          align='right'
          verticalAlign='top'
          wrapperStyle={legendWrapperStyles.vertical}
          onMouseEnter={onChartEnter(activePieChartData.map(entry => entry.Grouping))}
          onMouseLeave={onChartLeave}
        />
      </PieChart>
    </Wrapper>
  );
};

export default ActivePieChartViz;
