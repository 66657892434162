// @ts-strict-ignore
import { DESIGNER_SEQUENCE_ID } from '../shared/constants';
import { WorkspacePayload } from '../shared/dataTypes';

export const createDesignerWorkspacePayload = (): WorkspacePayload => ({
  // this is junk data, only to pass type check
  sequenceId: DESIGNER_SEQUENCE_ID,
  layerId: '-1',
  legacyReport: false,
  adhoc: true,
  sandbox: null,
});
