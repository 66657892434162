// @ts-strict-ignore
import { CustomGrouping } from '../../model/custom-grouping/customGrouping';
import { Direction } from '../../shared/dataTypes';

export interface CustomGroupingState {
  drawerStatus: CustomGroupingDrawerStatus | null;
  grouping: CustomGrouping | null;
  saveModalOpen: boolean;
  direction: Direction;
}

export enum CustomGroupingDrawerStatus {
  CREATE,
  EDIT,
  VIEW,
}

export const createDefaultState = (): CustomGroupingState => ({
  drawerStatus: null,
  grouping: null,
  saveModalOpen: false,
  direction: null,
});
