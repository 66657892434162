// @ts-strict-ignore
import StompJs, { IFrame, IMessage } from '@stomp/stompjs';
import {
  ErrorReportRawData,
  ReportRequestStatus,
  ReportUpdateHighlightMode,
} from 'algo-react-dataviz';
import { eventChannel } from 'redux-saga';
import { call, cancelled, put, take } from 'redux-saga/effects';
import { exportToCSV, exportToPDF } from '../components/report/helpers/exportUtils';
import {
  autoRecover,
  subscribeCSVTopic,
  subscribeEditTopic,
  subscribePendingUpdateTopic,
  subscribePrintReportTopic,
  subscribeProgressTopic,
  subscribeProxyTopic,
  subscribeRefreshNotificationTopic,
  subscribeReportNodesTopic,
  subscribeReportTopic,
} from '../components/shared/environment';
import { NotificationLevel } from '../shared/constants';
import {
  CSVData,
  RefreshNotification,
  ReportRawData,
  ReportRawDataNode,
} from '../shared/dataTypes';
import { clientUuid } from '../shared/utils';
import { enqueueSnackbar, openProxyDrawer, setGlobalOfflineFlag } from './ActionCreators';
import * as ActionTypes from './ActionTypes';
import {
  addReportPendingRequest,
  getRefreshChunk,
  removePendingDataNodesOperation,
  updateReportDataFromServer,
  updateReportDataNodesFromServer,
} from './ReportActionCreators';
import { sessionExpiredWarningToggle } from './UserProfileActionCreators';
import { regenerateTopLevelReports } from './WorkspaceActionCreators';
import { updateProgressFromServer } from './progress/reducer';
import { ValidReportRawData } from 'algo-react-dataviz';

const socketState = {
  connected: false,
};

export const wsConnected = (): boolean => socketState.connected;

function initWebsocket(sock: StompJs.Client) {
  return eventChannel(emitter => {
    function onInitialReportData(message: IMessage) {
      const reportData: ReportRawData = JSON.parse(message.body);
      if ((reportData as ErrorReportRawData).errMessage === 'UNAUTHORIZED') {
        emitter(sessionExpiredWarningToggle());
      } else {
        emitter(updateReportDataFromServer(reportData as ValidReportRawData));
      }
    }

    function onCSVMessage(message: IMessage) {
      const csvData: CSVData = JSON.parse(message.body);

      csvData.errMessage
        ? emitter(enqueueSnackbar(NotificationLevel.ERROR, csvData.errMessage))
        : emitter(exportToCSV(csvData.sequenceId, csvData.data));
    }

    function onProxyMessage(message: IMessage) {
      const { sequenceId, proxyConfig, errMessage } = JSON.parse(message.body);

      errMessage
        ? emitter(enqueueSnackbar(NotificationLevel.ERROR, errMessage))
        : emitter(openProxyDrawer(sequenceId, null, proxyConfig));
    }

    function onEditMessage(message: IMessage) {
      const { errMessage } = JSON.parse(message.body);

      errMessage && emitter(enqueueSnackbar(NotificationLevel.ERROR, errMessage));
    }

    function onPrintReportMessage(message) {
      // The binary message consists of 8 bytes for the sequence id followed by the report as PDF.
      const seqId = intFromBytes(message._binaryBody.slice(0, 8));
      emitter(exportToPDF(seqId, message._binaryBody.slice(8)));
    }

    const intFromBytes = (x: []) => {
      var val = 0;
      for (var i = 0; i < x.length; ++i) {
        val += x[i];
        if (i < x.length - 1) {
          val = val << 8;
        }
      }
      return val;
    };

    const onPendingUpdateMessage = (message: IMessage) => {
      const { requestId, sequenceId, action } = JSON.parse(message.body);

      if (action === 'pendingBegin') {
        emitter(
          addReportPendingRequest(
            sequenceId,
            requestId,
            ReportRequestStatus.PENDING,
            ReportUpdateHighlightMode.CELL_CHANGE,
          ),
        );
      } else if (action === 'pendingEnd') {
        emitter({
          type: ActionTypes.REMOVE_PENDING_OPERATION,
          payload: { sequenceId, requestId },
        });
      }
    };

    const onProgressMessage = (message: IMessage) =>
      emitter(updateProgressFromServer(JSON.parse(message.body)));

    function onNextReportDataChunk(message: IMessage) {
      const nodeData: ReportRawDataNode = JSON.parse(message.body);

      if (nodeData.errMessage === 'UNAUTHORIZED') {
        emitter(removePendingDataNodesOperation(nodeData));
        emitter(sessionExpiredWarningToggle());
      } else if ('NONE' === nodeData.rowPosition) {
        emitter(removePendingDataNodesOperation(nodeData));
      } else {
        emitter(updateReportDataNodesFromServer(nodeData));
      }
    }

    const onRefresh = (message: IMessage) => {
      const body: RefreshNotification = JSON.parse(message.body);
      const { sequenceId, dataId, requestId } = body;
      return emitter(getRefreshChunk(sequenceId, dataId, requestId));
    };

    const onConnect = () => {
      sock.subscribe(`${subscribeCSVTopic}${clientUuid}`, onCSVMessage);
      sock.subscribe(`${subscribeProxyTopic}${clientUuid}`, onProxyMessage);
      sock.subscribe(`${subscribeProgressTopic}${clientUuid}`, onProgressMessage);
      sock.subscribe(`${subscribePendingUpdateTopic}${clientUuid}`, onPendingUpdateMessage);
      sock.subscribe(`${subscribeEditTopic}${clientUuid}`, onEditMessage);
      sock.subscribe(`${subscribePrintReportTopic}${clientUuid}`, onPrintReportMessage);
      sock.subscribe(`${subscribeReportTopic}${clientUuid}`, onInitialReportData);
      sock.subscribe(`${subscribeReportNodesTopic}${clientUuid}`, onNextReportDataChunk);
      sock.subscribe(`${subscribeRefreshNotificationTopic}${clientUuid}`, onRefresh);
      emitter(setGlobalOfflineFlag(false));
      // emitter(enqueueSnackbar(NotificationLevel.INFO, `Connected to server`));
      socketState.connected = true;
      console.log(`Connected to server`);
      if (autoRecover) {
        emitter(regenerateTopLevelReports());
      }
    };

    const onClose = (errType: string) => (evt: CloseEvent) => {
      emitter(setGlobalOfflineFlag(true));
      if (socketState.connected) {
        // emitter(enqueueSnackbar(NotificationLevel.WARN, `${errType}`));
        console.warn(`${errType}`, evt.reason);
        socketState.connected = false;
      }
    };

    const onStompError = (errType: string) => (error: IFrame) => {
      if (socketState.connected) {
        // emitter(enqueueSnackbar(NotificationLevel.WARN, `${errType}`));
        console.warn(`${errType}`, error.body);
      }
    };

    const onWsError = (errType: string) => (evt: Event) => {
      if (socketState.connected) {
        // emitter(enqueueSnackbar(NotificationLevel.WARN, `${errType}`));
        console.warn(`${errType}`, evt);
      }
    };

    sock.onConnect = onConnect;
    sock.onStompError = onStompError('Stomp error');
    sock.onWebSocketError = onWsError('WS error');
    sock.onDisconnect = onStompError('Disconnected from server');
    sock.onWebSocketClose = onClose('WS closed');

    // this will connect and subscribe to the websocket
    sock.activate();

    return () => {
      // this will unsubscribe and disconnect the websocket
      sock.deactivate();
    };
  });
}

const wsSagas = (sock: StompJs.Client) => {
  return function* wsSagas() {
    const channel = yield call(initWebsocket, sock);
    try {
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  };
};

export default wsSagas;
