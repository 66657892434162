import FoliaPredicate from '../../lang/folia/model/predicate';

/**
 * Front-end models
 */

export enum GroupingStrategyType {
  PREDICATE = 'PREDICATE',
  CHARACTERISTIC = 'CHARACTERISTIC',
  REMAINDER = 'REMAINDER',
}

export const groupingStrategyTypes = [
  GroupingStrategyType.PREDICATE,
  GroupingStrategyType.CHARACTERISTIC,
  GroupingStrategyType.REMAINDER,
];

export const getLabel = (strategyType: GroupingStrategyType) =>
  ({
    [GroupingStrategyType.PREDICATE]: 'Where Clause Node',
    [GroupingStrategyType.CHARACTERISTIC]: 'Normal Grouping Node',
    [GroupingStrategyType.REMAINDER]: 'Other Node',
  }[strategyType]);

export type GroupingStrategy =
  | PredicateGroupingStrategy
  | CharacteristicGroupingStrategy
  | RemainderGroupingStrategy;

export interface PredicateGroupingStrategy {
  readonly type: GroupingStrategyType.PREDICATE;
  readonly predicate: FoliaPredicate;
}

export interface CharacteristicGroupingStrategy {
  readonly type: GroupingStrategyType.CHARACTERISTIC;
  readonly groupings: CharacteristicGrouping[];
}

export interface CharacteristicGrouping {
  readonly characteristicId: number;
  readonly breakpoints: string[] | null;
}

export interface RemainderGroupingStrategy {
  readonly type: GroupingStrategyType.REMAINDER;
}

/**
 * API DTOs
 */

export interface GroupingStrategyDto {
  readonly type: GroupingStrategyType;
  readonly predicate: FoliaPredicate | null;
  readonly groupings: CharacteristicGrouping[] | null;
}

/**
 * Transformations
 */

export const transformToDto = (strategy: GroupingStrategy | null): GroupingStrategyDto | null => {
  if (strategy === null) {
    return null;
  }

  switch (strategy.type) {
    case GroupingStrategyType.PREDICATE: {
      return {
        type: GroupingStrategyType.PREDICATE,
        predicate: strategy.predicate,
        groupings: null,
      };
    }

    case GroupingStrategyType.CHARACTERISTIC: {
      return {
        type: GroupingStrategyType.CHARACTERISTIC,
        predicate: null,
        groupings: strategy.groupings,
      };
    }

    case GroupingStrategyType.REMAINDER: {
      return {
        type: GroupingStrategyType.REMAINDER,
        predicate: null,
        groupings: null,
      };
    }
  }
};

export const transformFromDto = (
  strategyDto: GroupingStrategyDto | null,
): GroupingStrategy | null => {
  if (strategyDto === null) {
    return null;
  }

  switch (strategyDto.type) {
    case GroupingStrategyType.PREDICATE: {
      return {
        type: GroupingStrategyType.PREDICATE,
        predicate: strategyDto.predicate!,
      };
    }

    case GroupingStrategyType.CHARACTERISTIC: {
      return {
        type: GroupingStrategyType.CHARACTERISTIC,
        groupings: strategyDto.groupings!,
      };
    }

    case GroupingStrategyType.REMAINDER: {
      return {
        type: GroupingStrategyType.REMAINDER,
      };
    }
  }
};
