import {
  Person as PersonIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Formik, FormikErrors } from 'formik';
import { FC, useState } from 'react';
import AlgoLogo from '../../assets/workspace-analyzer.svg';
import { isOidcClientEnabled, isPsbcPortalEnabled } from '../shared/environment';
import './login.scss';

export interface LoginValues {
  username: string;
  password: string;
}

interface Props {
  login: (username: string, password: string) => void;
}

const Login: FC<Props> = ({ login }) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <Formik<LoginValues>
      initialValues={{ username: '', password: '' }}
      validate={({ username, password }): FormikErrors<LoginValues> => ({
        ...(!username ? { username: 'Required' } : {}),
        ...(!password ? { password: 'Required' } : {}),
      })}
      onSubmit={(values, { setSubmitting }) => {
        login(values.username, values.password);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <div className='container'>
          <div className='banner-container'>
            <img src={AlgoLogo} className='logo' alt='SS&C Algorithmics | Workspace Analyzer' />
            <div className='break' />
            <Typography className='legal-text'>
              Licensed Materials – Property of SS&C Technologies, Inc.©<br></br>
              Copyright SS&C Technologies, Inc. 2012, 2023. SS&C, Algo™ and Algorithmics™ are
              trademarks or registered trademarks of SS&C. Java and all Java-based trademarks and
              logos are trademarks or registered trademarks of Oracle and/or its affiliates.
              <br></br>Other product and service names might be trademarks of SS&C or other
              companies.
            </Typography>
          </div>

          <div className='login-container'>
            {isOidcClientEnabled || isPsbcPortalEnabled ? (
              <div />
            ) : (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <Grid xs={12} item>
                  <Box m={1}>
                    <TextField
                      className='login-field'
                      type='text'
                      name='username'
                      placeholder='User name'
                      variant='outlined'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      error={!!(errors.username && touched.username)}
                      helperText={errors.username && touched.username ? errors.username : ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <PersonIcon color={'disabled'} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <p>{errors.username && touched.username && errors.username}</p> */}
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box m={1}>
                    <TextField
                      className='login-field'
                      type={showPass ? 'text' : 'password'}
                      name='password'
                      placeholder='Password'
                      variant='outlined'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={!!(errors.password && touched.password)}
                      helperText={errors.password && touched.password ? errors.password : ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            onClick={() => setShowPass(!showPass)}
                            style={{ cursor: 'pointer' }}
                            data-testid='passwordHide'
                          >
                            {showPass ? (
                              <VisibilityOffIcon color='disabled' />
                            ) : (
                              <VisibilityIcon color='disabled' />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box m={1}>
                    <Button
                      className='login-button'
                      name='login'
                      type='submit'
                      disabled={
                        isSubmitting || values.username.length === 0 || values.password.length === 0
                      }
                      color='primary'
                      variant='contained'
                    >
                      Login
                    </Button>
                  </Box>
                </Grid>
              </form>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Login;
