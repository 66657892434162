// @ts-strict-ignore
import { History } from 'history';
import {
  Log,
  Logger,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts';
import {
  REACT_APP_REFRESH_PERMISSIONS_SECONDS,
  logLevel,
  publicUrl,
  refreshPermissionsSeconds,
} from '../../shared/environment';
import {
  AUTH_CONFIG,
  JWT_ISSUED_AT,
  JWT_USER_ID,
  REACT_APP_OIDC_CLIENT_REFRESH_SECONDS_BEFORE_EXPIRATION,
  idpHintKey,
  isAlertForBadRefreshTimeEnabled,
  oidcClientRefreshAlertWindow,
  oidcClientRefreshSecondsBeforeExpire,
  uriLoginCallback,
} from './authConfig';

export default class AuthService {
  private userManager: UserManager;
  private config: UserManagerSettings;
  private didAlert = false;

  constructor(
    private readonly history: History,
    setupLoggedInUser: (
      token: string,
      username: string,
      oidcLogout?: () => void,
      expiresIn?: () => number,
    ) => void,
  ) {
    this.config = {
      ...AUTH_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      extraQueryParams: this.getExtraQueryParams(),
    };

    this.userManager = new UserManager({
      ...this.config,
    });
    this.userManager.getUser();

    const loadedPre = (user: User) => {
      const jwt = this.parseJwt(user.id_token);
      const jwtUserId = jwt[JWT_USER_ID];
      const jwtIssued = jwt[JWT_ISSUED_AT];
      const exp = user.expires_in;
      const nowSeconds = Date.now() / 1000;
      const secsSinceIssued = nowSeconds - jwtIssued;
      if (
        secsSinceIssued <= oidcClientRefreshAlertWindow &&
        exp - refreshPermissionsSeconds <= oidcClientRefreshSecondsBeforeExpire
      ) {
        const msg = `${REACT_APP_OIDC_CLIENT_REFRESH_SECONDS_BEFORE_EXPIRATION}=${oidcClientRefreshSecondsBeforeExpire} is too big. Token expires in ${exp} second(s) and time must be allowed for ${REACT_APP_REFRESH_PERMISSIONS_SECONDS}=${refreshPermissionsSeconds} prior to refreshing.`;
        console.warn(msg);
        if (!this.didAlert && isAlertForBadRefreshTimeEnabled) {
          this.didAlert = true;
          alert(msg);
        }
      }
      setupLoggedInUser(user.id_token, jwtUserId, this.oidcLogout, () => user.expires_in);
    };

    this.userManager.getUser().then(user => {
      if (user !== null) {
        loadedPre(user);
      }
    });

    // Logger
    Log.setLogger(console);
    Log.setLevel(logLevel ? Log[logLevel] || Log.WARN : Log.WARN);

    this.userManager.events.addUserLoaded(user => {
      loadedPre(user);
      if (window.location.href.includes(uriLoginCallback.path)) {
        this.navigateToScreen(user.state as string);
      }
    });

    this.userManager.events.addUserUnloaded(() => {
      if (window.location.href.includes(uriLoginCallback.path)) {
        this.navigateToScreen('/');
      }
    });

    this.userManager.events.addSilentRenewError(e => {
      Logger.error('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired(() => {
      Logger.error('token expired');
      this.signinSilent();
    });
  }

  private getExtraQueryParams = () => {
    const urlParams = new URLSearchParams(this.history?.location?.search);
    const idpHint = urlParams.get(idpHintKey);
    return idpHint ? { [`${idpHintKey}`]: idpHint } : {};
  };

  private navigateToScreen = (url: string) => {
    this.history.replace(url);
  };

  private parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(`oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`),
    );
    return !!oidcStorage && !!oidcStorage.id_token;
  };

  signinRedirect = (url: string) => {
    this.userManager.signinRedirect({
      state: url,
    });
  };

  signinRedirectCallback = (url: string) => {
    this.userManager.signinRedirectCallback(url);
  };

  signinSilent = () => {
    this.userManager
      .signinSilent()
      .then(user => {
        Logger.debug(`OIDC signed in (user = ${user})`);
      })
      .catch(err => {
        Logger.error(err);
      });
  };

  signinSilentCallback = () => {
    this.userManager.signinSilentCallback();
  };

  signoutRedirectCallback = () => {
    this.userManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      this.navigateToScreen(publicUrl);
    });
    this.userManager.clearStaleState();
  };

  oidcLogout = () => {
    Logger.debug('oidc logout');
    this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.userManager.clearStaleState();
  };
}
