// @ts-strict-ignore
import { ReactiveDataGrid } from 'algo-react-dataviz';
import ActivePieChartViz from './ActivePieChartViz';
import AreaChartViz from './AreaChartViz';
import BarChartViz from './BarChartViz';
import BrushedBarChartViz from './BrushBarChartViz';
import BulletGraphViz from './BulletGraphViz';
import ComposedChartViz from './ComposedChartViz';
import LineChartViz from './LineChartViz';
import MixedBarChartViz from './MixedBarChartViz';
import PieChartViz from './PieChartViz';
import RadialBarChartViz from './RadialBarChartViz';
// import ReactiveMapChartViz from '../charting/ReactiveMapChartViz';
import LegacyXYLineChartViz from './LegacyXYLineChartViz';
import LegacyXYStackedBarChartViz from './LegacyXYStackedBarChartViz';
import ScatterChartViz from './ScatterChartViz';
import TreeMapViz from './TreeMapViz';
import TwoLevelPieChartViz from './TwoLevelPieChartViz';
import XYAreaChartViz from './XYAreaChartViz';
import XYLineChartViz from './XYLineChartViz';

export const visualizationComponents = {
  DX_TABLE: ReactiveDataGrid,
  // MAP_CHART: ReactiveMapChartViz, // not production-ready
  BAR_CHART: BarChartViz,
  STACKED_BAR_CHART: BarChartViz,
  MIXED_BAR_CHART: MixedBarChartViz,
  BRUSH_BAR_CHART: BrushedBarChartViz,
  STACKED_BRUSH_BAR_CHART: BrushedBarChartViz,
  LINE_GRAPH: LineChartViz,
  XY_LINE_CHART: XYLineChartViz,
  PIE_CHART: PieChartViz,
  AREA_CHART: AreaChartViz,
  XY_AREA_CHART: XYAreaChartViz,
  ACTIVE_PIE_CHART: ActivePieChartViz,
  TWO_LEVEL_PIE_CHART: TwoLevelPieChartViz,
  TREE_MAP: TreeMapViz,
  SCATTER_CHART: ScatterChartViz,
  BUBBLE_CHART: ScatterChartViz,
  COMPOSED_CHART: ComposedChartViz,
  RADIAL_BAR_CHART: RadialBarChartViz,
  BULLET_GRAPH: BulletGraphViz,
};

export const legacyVisualizationComponents = {
  XY_LINE_CHART: LegacyXYLineChartViz,
  XY_SPLINE_LINE_CHART: LegacyXYLineChartViz,
  XY_SPLINE_LINE_ONLY_CHART: LegacyXYLineChartViz,
  XY_LINE_NO_SYMBOL_CHART: LegacyXYLineChartViz,
  XY_SYMBOL_NO_LINE_CHART: LegacyXYLineChartViz,
  XY_STACKED_BAR_CHART: LegacyXYStackedBarChartViz,
  XY_BAR_CHART: LegacyXYStackedBarChartViz,
};

export const getVisualizationComponent = (type: string, isLegacy = false) =>
  ((isLegacy && legacyVisualizationComponents[type]) || visualizationComponents[type]) ?? null;
