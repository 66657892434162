// @ts-strict-ignore
import { defaultReportDesignerBenchmarks } from '../shared/constants';
import { Benchmark } from '../shared/dataTypes';
import * as ActionTypes from './ActionTypes';

const defaultState: Benchmark[] = defaultReportDesignerBenchmarks;

export const benchmarks = (state: Benchmark[] = defaultState, action): Benchmark[] => {
  switch (action.type) {
    case ActionTypes.SET_REPORT_DESIGNER_BENCHMARKS:
      return { ...action.payload.benchmarks };

    case ActionTypes.SET_BENCHMARK: {
      const benchmarks: Benchmark[] = { ...state };
      benchmarks[action.payload.index] = {
        ...benchmarks[action.payload.index],
        [action.payload.propertyName]: action.payload.propertyValue,
      };

      return [benchmarks[0], benchmarks[1]];
    }

    case ActionTypes.COPY_BENCHMARKS:
      return action.payload || defaultState;
    default:
      return state;
  }
};
