import { ANTLRErrorListener } from 'antlr4ts';

export type ANTLRErrorParameters<T> = Parameters<NonNullable<ANTLRErrorListener<T>['syntaxError']>>;

class ErrorCollector<T, E> implements ANTLRErrorListener<T> {
  private errors: E[] = [];

  constructor(private createError: (...params: ANTLRErrorParameters<T>) => E) {}

  syntaxError(...params: ANTLRErrorParameters<T>) {
    this.addError(this.createError(...params));
  }

  private addError = (error: E) => {
    this.errors = [...this.errors, error];
  };

  getErrors = () => [...this.errors];
}

export default ErrorCollector;
