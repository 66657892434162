// @ts-strict-ignore
import { ROOT_NODE_NAME } from './customGrouping';
import GroupingNodeController from './groupingNodeController';
import { GroupingStrategyType } from './groupingStrategy';

const createExampleGroupingTree = () =>
  GroupingNodeController.fromOperations([
    g => g.setName([], ROOT_NODE_NAME),

    g =>
      g.addNode([], {
        name: 'CAD',
        groupingStrategy: {
          type: GroupingStrategyType.PREDICATE,
          predicate: {
            code: 'key[Instrument Currency] == "CAD"',
            tokens: [
              {
                type: 'KEY_EXPRESSION',
                text: 'key[Instrument Currency]',
              },
              {
                type: 'COMP_EQ',
                text: '==',
              },
              {
                type: 'STRING_LITERAL',
                text: '"CAD"',
              },
              {
                type: 'EOL',
                text: null,
              },
              {
                type: 'EOF',
                text: null,
              },
            ],
          },
        },
      }),
    g =>
      g.addNode([g.children[0].id], {
        name: 'Industry Sector',
        groupingStrategy: {
          type: GroupingStrategyType.CHARACTERISTIC,
          groupings: [],
        },
      }),

    g =>
      g.addNode([], {
        name: 'USD',
        groupingStrategy: {
          type: GroupingStrategyType.PREDICATE,
          predicate: {
            code: 'key[Instrument Currency] == "USD"',
            tokens: [
              {
                type: 'KEY_EXPRESSION',
                text: 'key[Instrument Currency]',
              },
              {
                type: 'COMP_EQ',
                text: '==',
              },
              {
                type: 'STRING_LITERAL',
                text: '"USD"',
              },
              {
                type: 'EOL',
                text: null,
              },
              {
                type: 'EOF',
                text: null,
              },
            ],
          },
        },
      }),
    g =>
      g.addNode([g.children[1].id], {
        name: 'Duration',
        groupingStrategy: {
          type: GroupingStrategyType.CHARACTERISTIC,
          groupings: [],
        },
      }),

    g => g.addNode([], { name: 'Empty Folder' }),
    g => g.addNode([g.children[2].id], { name: 'Empty Subfolder' }),
    g => g.addNode([g.children[2].id], { name: 'Empty Subfolder 2' }),

    g =>
      g.addNode([], {
        name: 'Other Currencies',
        groupingStrategy: {
          type: GroupingStrategyType.REMAINDER,
        },
      }),
  ]).serialize();

export default createExampleGroupingTree;
