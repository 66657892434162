// @ts-strict-ignore
import { AccessType, FolderType } from '../shared/constants';
import { AdminState, ChangeFolderPermission, FolderPermissions } from '../shared/dataTypes';
import {
  ADD_LOADING_GROUP_MEMBERS,
  ADD_USERS_TO_GROUP,
  CREATE_GROUP_LIKE,
  CREATE_USER_LIKE,
  DELETE_GROUPS,
  DELETE_USERS,
  REMOVE_LOADING_GROUP_MEMBERS,
  REMOVE_USERS_FROM_GROUP,
  SELECT_GROUP,
  SELECT_USER,
  SET_COMPONENT_PERMISSIONS,
  SET_CONTEXT_PERMISSION_RULE,
  SET_CREATE_GROUP_DRAWER_OPEN,
  SET_CREATE_LIKE_CURRENT,
  SET_CREATE_USER_DRAWER_OPEN,
  SET_DISABLED_PORTFOLIO_PERMISSIONS,
  SET_FOLDER_PERMISSION,
  SET_GROUPS,
  SET_GROUP_PORTFOLIO_PERMISSIONS,
  SET_PERMISSIONS,
  SET_PORTFOLIO_PERMISSIONS,
  SET_USERS,
  SET_USER_FIRST_LAST_NAME,
  SET_USER_LOCKED,
} from './ActionTypes';
import {
  addLoadingGroupMembers,
  addUsersToGroup,
  createGroupLike,
  createUserLike,
  deleteGroups,
  deleteUsers,
  removeLoadingGroupMembers,
  removeUsersFromGroup,
  selectGroup,
  selectUser,
  setComponentPermissions,
  setContextPermissionRule,
  setCreateGroupDrawerOpen,
  setCreateLikeCurrent,
  setCreateUserDrawerOpen,
  setDisabledPortfolioPermissions,
  setFolderPermission,
  setGroupPortfolioPermissions,
  setGroups,
  setPermissions,
  setPortfolioPermissions,
  setUserFirstLastName,
  setUsers,
  _setUserLocked,
} from './adminActionCreators';

const defaultState: AdminState = { users: [], groups: [], disabledPortfolioPermissions: true };

type Action =
  | ReturnType<typeof setCreateUserDrawerOpen>
  | ReturnType<typeof setCreateGroupDrawerOpen>
  | ReturnType<typeof setCreateLikeCurrent>
  | ReturnType<typeof selectUser>
  | ReturnType<typeof selectGroup>
  | ReturnType<typeof setUsers>
  | ReturnType<typeof setDisabledPortfolioPermissions>
  | ReturnType<typeof setGroups>
  | ReturnType<typeof setPermissions>
  | ReturnType<typeof setComponentPermissions>
  | ReturnType<typeof setPortfolioPermissions>
  | ReturnType<typeof setGroupPortfolioPermissions>
  | ReturnType<typeof setFolderPermission>
  | ReturnType<typeof setContextPermissionRule>
  | ReturnType<typeof addUsersToGroup>
  | ReturnType<typeof removeUsersFromGroup>
  | ReturnType<typeof addLoadingGroupMembers>
  | ReturnType<typeof removeLoadingGroupMembers>
  | ReturnType<typeof deleteUsers>
  | ReturnType<typeof deleteGroups>
  | ReturnType<typeof createGroupLike>
  | ReturnType<typeof createUserLike>
  | ReturnType<typeof setUserFirstLastName>
  | ReturnType<typeof _setUserLocked>;

const admin = (state: AdminState = defaultState, action: Action): AdminState => {
  switch (action.type) {
    case SET_CREATE_USER_DRAWER_OPEN:
      return {
        ...state,
        createUserDrawerOpen: action.payload,
        createLikeCurrent: action.payload ? state.createLikeCurrent : undefined,
      };
    case SET_CREATE_GROUP_DRAWER_OPEN:
      return {
        ...state,
        createGroupDrawerOpen: action.payload,
        createLikeCurrent: action.payload ? state.createLikeCurrent : undefined,
      };
    case SET_CREATE_LIKE_CURRENT:
      return { ...state, createLikeCurrent: action.payload };
    case SELECT_USER:
      return { ...state, selectedUser: action.payload };
    case SET_DISABLED_PORTFOLIO_PERMISSIONS:
      return { ...state, disabledPortfolioPermissions: action.payload };
    case SELECT_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
        users: state.users.map(u => ({
          ...u,
          // This variable enables the GroupMembers component to sort users by selection
          // but not re-sort them when the user selects or deselects a user.
          includedGroupWhenSelected: u.groups.includes(action.payload),
        })),
      };
    case SET_USERS:
      return { ...state, users: action.payload };
    case SET_GROUPS:
      return { ...state, groups: action.payload };
    case SET_PERMISSIONS:
      return { ...state, allPermissions: action.payload };
    case SET_COMPONENT_PERMISSIONS:
      return { ...state, componentPermissions: action.payload };
    case SET_PORTFOLIO_PERMISSIONS:
      return { ...state, portfolioPermissions: action.payload };
    case SET_GROUP_PORTFOLIO_PERMISSIONS:
      return {
        ...state,
        portfolioPermissions: {
          ...state.portfolioPermissions,
          [action.payload.group]: action.payload.permissions,
        },
      };
    case SET_CONTEXT_PERMISSION_RULE:
      const { contextPermissions: cp } = state.allPermissions;
      return {
        ...state,
        allPermissions: {
          ...state.allPermissions,
          contextPermissions: cp.find(r => r.groupName === action.payload.groupName)
            ? cp.map(r => (r.groupName === action.payload.groupName ? action.payload : r))
            : cp.concat(action.payload),
        },
      };
    case SET_FOLDER_PERMISSION:
      return {
        ...state,
        allPermissions: {
          ...state.allPermissions,
          reportPermissions: applyPermission(
            state.allPermissions.reportPermissions,
            action.payload,
            FolderType.REPORT,
          ),
          workspacePermissions: applyPermission(
            state.allPermissions.workspacePermissions,
            action.payload,
            FolderType.WORKSPACE,
          ),
          sandboxPermissions: applyPermission(
            state.allPermissions.sandboxPermissions,
            action.payload,
            FolderType.SANDBOX,
          ),
        },
      };
    case ADD_LOADING_GROUP_MEMBERS:
      return {
        ...state,
        users: state.users.map(u =>
          action.payload.userNames.includes(u.userName)
            ? { ...u, groupsLoading: (u.groupsLoading || []).concat(action.payload.group) }
            : u,
        ),
      };
    case REMOVE_LOADING_GROUP_MEMBERS:
      return {
        ...state,
        users: state.users.map(u =>
          action.payload.userNames.includes(u.userName)
            ? {
                ...u,
                groupsLoading: (u.groupsLoading || []).filter(g => g !== action.payload.group),
              }
            : u,
        ),
      };
    case ADD_USERS_TO_GROUP:
      return {
        ...state,
        users: state.users.map(u =>
          action.payload.userNames.includes(u.userName) && !u.groups.includes(action.payload.group)
            ? { ...u, groups: u.groups.concat(action.payload.group) }
            : u,
        ),
      };
    case REMOVE_USERS_FROM_GROUP:
      return {
        ...state,
        users: state.users.map(u =>
          action.payload.userNames.includes(u.userName)
            ? { ...u, groups: u.groups.filter(g => g !== action.payload.group) }
            : u,
        ),
      };
    case DELETE_USERS:
      return {
        ...state,
        users: state.users.filter(user => !action.payload.includes(user.userName)),
        selectedUser: action.payload.includes(state.selectedUser)
          ? state.users[0].userName
          : state.selectedUser,
      };
    case DELETE_GROUPS:
      return {
        ...state,
        groups: state.groups.filter(group => !action.payload.includes(group)),
        users: state.users.map(user => ({
          ...user,
          groups: user.groups.filter(group => !action.payload.includes(group)),
        })),
      };
    case CREATE_GROUP_LIKE:
      return {
        ...state,
        groups: [...state.groups, action.payload.groupName],
      };
    case CREATE_USER_LIKE:
      const groups = state.users.find(u => u.userName === action.payload.sourceUser)?.groups || [];
      const { userName, firstName, lastName } = action.payload;
      return {
        ...state,
        users: [
          ...state.users,
          { userName, firstName, lastName, groups, groupsLoading: [], locked: false },
        ],
      };

    case SET_USER_FIRST_LAST_NAME:
      const targetUser = state.users.find(u => u.userName === action.payload.user);
      return {
        ...state,
        users: state.users.map(userObject =>
          userObject.userName === targetUser.userName
            ? {
                ...userObject,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
              }
            : userObject,
        ),
      };

    case SET_USER_LOCKED:
      const user = state.users.find(u => u.userName === action.payload.user);
      return {
        ...state,
        users: state.users.map(userObject =>
          userObject.userName === user.userName
            ? {
                ...userObject,
                locked: action.payload.locked,
              }
            : userObject,
        ),
      };

    default:
      return state;
  }
};

const applyPermission = (
  folderPermissions: FolderPermissions,
  change: ChangeFolderPermission,
  folderType: FolderType,
) =>
  change.folderType === folderType
    ? {
        ...folderPermissions,
        [change.groupName]: {
          ...folderPermissions[change.groupName],
          folderAccess: {
            ...folderPermissions[change.groupName]?.folderAccess,
            [change.folderName]: {
              read:
                change.accessType === AccessType.READ
                  ? change.value
                  : folderPermissions[change.groupName]?.folderAccess[change.folderName]?.read,
              write:
                change.accessType === AccessType.WRITE
                  ? change.value
                  : folderPermissions[change.groupName]?.folderAccess[change.folderName]?.write,
            },
          },
        },
      }
    : folderPermissions;

export default admin;
