import { Modifier } from 'algo-react-dataviz';
import { Characteristic, Direction } from '../../shared/dataTypes';
import { composeCellId, decomposeCellId } from '../../shared/utils';

export enum StyleTargetType {
  CELL = 'cell',
  ROW = 'row',
  COLUMN = 'column',
  NORMAL_GROUPING = 'normalGrouping',
}

export type StyleTargetTypeForSelection =
  | StyleTargetType.CELL
  | StyleTargetType.ROW
  | StyleTargetType.COLUMN;

export const styleTargetTypesForSelection: StyleTargetTypeForSelection[] = [
  StyleTargetType.CELL,
  StyleTargetType.ROW,
  StyleTargetType.COLUMN,
];

export interface BaseStyleTarget {
  type: StyleTargetType;

  rowId?: string | never;
  columnId?: string | never;

  direction?: Direction | never;
  charId?: number | never;
  modifier?: Modifier | never;
}

export interface CellStyleTarget extends BaseStyleTarget {
  type: StyleTargetType.CELL;
  rowId: string;
  columnId: string;

  direction?: never;
  charId?: never;
  modifier?: never;
}

export interface RowStyleTarget extends BaseStyleTarget {
  type: StyleTargetType.ROW;
  rowId: string;

  columnId?: never;
  direction?: never;
  charId?: never;
  modifier?: never;
}

export interface ColumnStyleTarget extends BaseStyleTarget {
  type: StyleTargetType.COLUMN;
  columnId: string;

  rowId?: never;
  direction?: never;
  charId?: never;
  modifier?: never;
}

export interface NormalGroupingStyleTarget extends BaseStyleTarget {
  type: StyleTargetType.NORMAL_GROUPING;
  direction: Direction;
  charId: number;
  modifier: Modifier;

  rowId?: never;
  columnId?: never;
}

export type StyleTarget =
  | CellStyleTarget
  | RowStyleTarget
  | ColumnStyleTarget
  | NormalGroupingStyleTarget;

// For performance
export const getHash = (target: StyleTarget) => {
  switch (target.type) {
    case StyleTargetType.CELL: {
      return `${StyleTargetType.CELL}-${composeCellId(target.rowId, target.columnId)}`;
    }

    case StyleTargetType.ROW: {
      return `${StyleTargetType.ROW}-${target.rowId}`;
    }

    case StyleTargetType.COLUMN: {
      return `${StyleTargetType.COLUMN}-${target.columnId}`;
    }

    case StyleTargetType.NORMAL_GROUPING: {
      return `${StyleTargetType.NORMAL_GROUPING}-${target.direction}-${target.charId}-${target.modifier}`;
    }
  }
};

export const createStyleTargetsForSelection = (
  type: StyleTargetTypeForSelection,
  cellIds: string[],
): StyleTarget[] => {
  if (cellIds.length === 0) {
    return [];
  }

  const decomposedIds = cellIds.map(decomposeCellId);

  switch (type) {
    case StyleTargetType.CELL: {
      return decomposedIds.map<CellStyleTarget>(decomposedId => ({
        type: StyleTargetType.CELL,
        ...decomposedId,
      }));
    }

    case StyleTargetType.ROW: {
      return Array.from(new Set(decomposedIds.map(({ rowId }) => rowId))).map<RowStyleTarget>(
        rowId => ({
          type: StyleTargetType.ROW,
          rowId,
        }),
      );
    }

    case StyleTargetType.COLUMN: {
      return Array.from(
        new Set(decomposedIds.map(({ columnId }) => columnId)),
      ).map<ColumnStyleTarget>(columnId => ({
        type: StyleTargetType.COLUMN,
        columnId,
      }));
    }
  }
};

export const createStyleTargetForNormalGrouping = (
  direction: Direction,
  { charId, modifier }: Characteristic,
): StyleTarget => ({
  type: StyleTargetType.NORMAL_GROUPING,
  direction,
  charId,
  modifier,
});

/**
 * We have a partial order on the set of style targets, by asking which
 * rule targets "subsume" which others. We say target A subsumes target B
 * exactly when rules with target B would apply to target A. For example,
 * if target A is a cell target and B is a row target for the row occupied
 * by A, we have A ⊲ B.
 *
 * This function returns whether A ⊴ B. So if A ⊳ B or A ∥ B (incomparable;
 * neither subsumes the other), we return `false`.
 */
export const styleTargetApplies = (targetA: StyleTarget, targetB: StyleTarget) => {
  switch (getCombinedId(targetA.type, targetB.type)) {
    case getCombinedId(StyleTargetType.CELL, StyleTargetType.CELL): {
      return targetA.rowId === targetB.rowId && targetA.columnId === targetB.columnId;
    }

    case getCombinedId(StyleTargetType.CELL, StyleTargetType.ROW): {
      return false;
    }

    case getCombinedId(StyleTargetType.CELL, StyleTargetType.COLUMN): {
      return false;
    }

    case getCombinedId(StyleTargetType.ROW, StyleTargetType.CELL): {
      return targetA.rowId === targetB.rowId;
    }

    case getCombinedId(StyleTargetType.ROW, StyleTargetType.ROW): {
      return targetA.rowId === targetB.rowId;
    }

    case getCombinedId(StyleTargetType.ROW, StyleTargetType.COLUMN): {
      return false;
    }

    case getCombinedId(StyleTargetType.COLUMN, StyleTargetType.CELL): {
      return targetA.columnId === targetB.columnId;
    }

    case getCombinedId(StyleTargetType.COLUMN, StyleTargetType.ROW): {
      return false;
    }

    case getCombinedId(StyleTargetType.COLUMN, StyleTargetType.COLUMN): {
      return targetA.columnId === targetB.columnId;
    }
  }
};

export const styleTargetSupersedes = (() => {
  const precedenceOrder = [StyleTargetType.CELL, StyleTargetType.ROW, StyleTargetType.COLUMN];

  return (targetA: StyleTarget, targetB: StyleTarget) =>
    precedenceOrder.indexOf(targetA.type) < precedenceOrder.indexOf(targetB.type);
})();

// For the purpose of flattening a two-dimensional switch/case situation
const getCombinedId = (typeA: StyleTargetType, typeB: StyleTargetType) => [typeA, typeB].join('x');
