// @ts-strict-ignore
import { Reducer } from '@reduxjs/toolkit';
import { ScenarioSet } from '../../model/scenariosConfig';
import { DataSourceItemForDx } from '../../shared/dataTypes';
import {
  closeReportScenarioDrawer,
  openReportScenarioDrawer,
  ReportScenarioDrawerActionType,
  setReportScenarioDrawerSets,
  setReportScenarioDrawerTimesteps,
} from './actionCreators';

type Action =
  | ReturnType<typeof openReportScenarioDrawer>
  | ReturnType<typeof closeReportScenarioDrawer>
  | ReturnType<typeof setReportScenarioDrawerSets>
  | ReturnType<typeof setReportScenarioDrawerTimesteps>;

interface State {
  open: boolean;
  sequenceId: number;
  sets?: ScenarioSet[];
  timestepDataSource?: DataSourceItemForDx<string>[];
}

const reducer: Reducer<State, Action> = (
  state = { open: false, sequenceId: undefined },
  action,
) => {
  switch (action.type) {
    case ReportScenarioDrawerActionType.OPEN:
      return { open: true, sequenceId: action.payload.sequenceId };
    case ReportScenarioDrawerActionType.CLOSE:
      return { open: false, sequenceId: undefined };
    case ReportScenarioDrawerActionType.SET_SETS:
      return { ...state, sets: action.payload };
    case ReportScenarioDrawerActionType.SET_TIMESTEPS:
      return { ...state, timestepDataSource: action.payload };
    default:
      return state;
  }
};

export default reducer;
