// @ts-strict-ignore
import { OidcMetadata, UserManagerSettings } from 'oidc-client-ts';
import { parse } from 'uri-js';
import {
  getProp,
  isOidcClientEnabled,
  isPortalEnabled,
  isPsbcPortalEnabled,
  toBool,
} from '../../shared/environment';

//////////////////////////////////////////////////////////////////////
// PLEASE ... when making changes to this file:                     //
//  - update backstage/src/main/env/.env.ui                         //
//  - update backstage/src/main/jib/usr/local/algo/stadium/.env.ui  //
//////////////////////////////////////////////////////////////////////

const REACT_APP_OIDC_CLIENT_AUTHORITY = 'REACT_APP_OIDC_CLIENT_AUTHORITY';
const REACT_APP_OIDC_CLIENT_CLIENT_ID = 'REACT_APP_OIDC_CLIENT_CLIENT_ID';
const REACT_APP_OIDC_CLIENT_REDIRECT_URI = 'REACT_APP_OIDC_CLIENT_REDIRECT_URI';
const REACT_APP_OIDC_CLIENT_AUTOMATIC_SILENT_RENEW = 'REACT_APP_OIDC_CLIENT_AUTOMATIC_SILENT_RENEW';
export const REACT_APP_OIDC_CLIENT_REFRESH_SECONDS_BEFORE_EXPIRATION =
  'REACT_APP_OIDC_CLIENT_REFRESH_SECONDS_BEFORE_EXPIRATION';
const REACT_APP_OIDC_CLIENT_REFRESH_ALERT_WINDOW = 'REACT_APP_OIDC_CLIENT_REFRESH_ALERT_WINDOW';
const REACT_APP_OIDC_CLIENT_LOAD_USER_INFO = 'REACT_APP_OIDC_CLIENT_LOAD_USER_INFO';
const REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI = 'REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI';
const REACT_APP_OIDC_CLIENT_POST_LOGOUT_REDIRECT_URI =
  'REACT_APP_OIDC_CLIENT_POST_LOGOUT_REDIRECT_URI';
const REACT_APP_OIDC_CLIENT_RESPONSE_TYPE = 'REACT_APP_OIDC_CLIENT_RESPONSE_TYPE';
const REACT_APP_OIDC_CLIENT_SCOPE = 'REACT_APP_OIDC_CLIENT_SCOPE';

const REACT_APP_OIDC_CLIENT_META_ISSUER = 'REACT_APP_OIDC_CLIENT_META_ISSUER';
const REACT_APP_OIDC_CLIENT_META_JWKS_URI = 'REACT_APP_OIDC_CLIENT_META_JWKS_URI';
const REACT_APP_OIDC_CLIENT_META_AUTHORIZATION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_AUTHORIZATION_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_TOKEN_ENDPOINT = 'REACT_APP_OIDC_CLIENT_META_TOKEN_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_USERINFO_ENDPOINT = 'REACT_APP_OIDC_CLIENT_META_USERINFO_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_END_SESSION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_END_SESSION_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_CHECK_SESSION_IFRAME =
  'REACT_APP_OIDC_CLIENT_META_CHECK_SESSION_IFRAME';
const REACT_APP_OIDC_CLIENT_META_REVOCATION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_REVOCATION_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_INTROSPECTION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_INTROSPECTION_ENDPOINT';

const REACT_APP_OIDC_CLIENT_JWT_USER_ID = 'REACT_APP_OIDC_CLIENT_JWT_USER_ID';
const REACT_APP_OIDC_CLIENT_JWT_ISSUED_AT = 'REACT_APP_OIDC_CLIENT_JWT_ISSUED_AT';

const REACT_APP_OIDC_CLIENT_LOGOUT = 'REACT_APP_OIDC_CLIENT_LOGOUT';
const REACT_APP_OIDC_CLIENT_LOGOUT_CALLBACK = 'REACT_APP_OIDC_CLIENT_LOGOUT_CALLBACK';
const REACT_APP_OIDC_CLIENT_SILENT_RENEW = 'REACT_APP_OIDC_CLIENT_SILENT_RENEW';
const REACT_APP_OIDC_CLIENT_ROOT = 'REACT_APP_OIDC_CLIENT_ROOT';

const REACT_APP_OIDC_CLIENT_IDP_HINT_KEY = 'REACT_APP_OIDC_CLIENT_IDP_HINT_KEY';

const uriOrDefault = (str: string, dfault: string) => {
  try {
    return parse(getProp(str));
  } catch (err) {
    return parse(dfault);
  }
};

export const uriLoginCallback = uriOrDefault(REACT_APP_OIDC_CLIENT_REDIRECT_URI, '/signin-oidc');
export const uriLogout = uriOrDefault(REACT_APP_OIDC_CLIENT_LOGOUT, '/logout');
export const uriLogoutCallback = uriOrDefault(
  REACT_APP_OIDC_CLIENT_LOGOUT_CALLBACK,
  '/logout/callback',
);
export const uriSilentRenew = uriOrDefault(REACT_APP_OIDC_CLIENT_SILENT_RENEW, '/silentrenew');
export const uriRoot = uriOrDefault(REACT_APP_OIDC_CLIENT_ROOT, '/');

export const idpHintKey = getProp(REACT_APP_OIDC_CLIENT_IDP_HINT_KEY) || 'kc_idp_hint';
export const oidcClientRefreshSecondsBeforeExpire = Number(
  getProp(REACT_APP_OIDC_CLIENT_REFRESH_SECONDS_BEFORE_EXPIRATION) ?? 60,
);
export const oidcClientRefreshAlertWindow = Number(
  getProp(REACT_APP_OIDC_CLIENT_REFRESH_ALERT_WINDOW) ?? 10,
);

export const isAlertForBadRefreshTimeEnabled =
  toBool((window as any)._env_?.REACT_APP_OIDC_CLIENT_ALERT_ON_BAD_REFRESH_EXPIRATION_TIME) ||
  toBool(process.env.REACT_APP_OIDC_CLIENT_ALERT_ON_BAD_REFRESH_EXPIRATION_TIME, true);

const IDENTITY_CONFIG: UserManagerSettings = {
  authority: getProp(REACT_APP_OIDC_CLIENT_AUTHORITY),
  client_id: getProp(REACT_APP_OIDC_CLIENT_CLIENT_ID),
  redirect_uri: getProp(REACT_APP_OIDC_CLIENT_REDIRECT_URI),
  automaticSilentRenew: toBool(getProp(REACT_APP_OIDC_CLIENT_AUTOMATIC_SILENT_RENEW) ?? 'true'),
  loadUserInfo: toBool(getProp(REACT_APP_OIDC_CLIENT_LOAD_USER_INFO) ?? 'true'),
  silent_redirect_uri: getProp(REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI),
  post_logout_redirect_uri: getProp(REACT_APP_OIDC_CLIENT_POST_LOGOUT_REDIRECT_URI),
  response_type: getProp(REACT_APP_OIDC_CLIENT_RESPONSE_TYPE) ?? 'code',
  scope: getProp(REACT_APP_OIDC_CLIENT_SCOPE) ?? 'openid profile email',
  accessTokenExpiringNotificationTimeInSeconds: oidcClientRefreshSecondsBeforeExpire,
};

const OIDC_METADATA: Partial<OidcMetadata> = {
  issuer: getProp(REACT_APP_OIDC_CLIENT_META_ISSUER),
  jwks_uri: getProp(REACT_APP_OIDC_CLIENT_META_JWKS_URI),
  authorization_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_AUTHORIZATION_ENDPOINT),
  token_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_TOKEN_ENDPOINT),
  userinfo_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_USERINFO_ENDPOINT),
  end_session_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_END_SESSION_ENDPOINT),
  check_session_iframe: getProp(REACT_APP_OIDC_CLIENT_META_CHECK_SESSION_IFRAME),
  revocation_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_REVOCATION_ENDPOINT),
  introspection_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_INTROSPECTION_ENDPOINT),
};

const hasMetadataConfig = Object.values(OIDC_METADATA).some(
  value => ![null, undefined].includes(value),
);

export const JWT_USER_ID = getProp(REACT_APP_OIDC_CLIENT_JWT_USER_ID) ?? 'sub';
export const JWT_ISSUED_AT = getProp(REACT_APP_OIDC_CLIENT_JWT_ISSUED_AT) ?? 'iat';
export const AUTH_CONFIG: UserManagerSettings = {
  ...IDENTITY_CONFIG,
  ...(hasMetadataConfig ? { metadata: { ...OIDC_METADATA } } : {}),
};

export const isNativeAuthentication = () =>
  !isPortalEnabled && !isOidcClientEnabled && !isPsbcPortalEnabled;
